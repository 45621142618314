import React, { useContext, useState } from "react";
import axios from "axios";
import tingle from "tingle.js";

// Style
import "tingle.js/dist/tingle.min.css";
import "./CardDisciplina.scss";
import { GGgetMode } from "../../services/gglocalstorage";

import {
	MDBBtn,
	MDBBadge,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
} from "mdbreact";

import {
	Col,
	Row,
	Card,
	Accordion,
	useAccordionToggle,
	AccordionContext,
} from "react-bootstrap";

import PropTypes from "prop-types";
import { NormalizeString } from "../../helpers/Formatters";

export default function CardDisciplina({
	composicoesCurriculares,
	modalidade,
	tipo,
}) {
	CardDisciplina.propTypes = {
		composicoesCurriculares: PropTypes.object.isRequired,
		modalidade: PropTypes.string.isRequired,
		tipo: PropTypes.string.isRequired,
	};

	// Cria o objeto do modal
	let modal;

	// Verifica se está no browser para instanciar o tingle.js (lib só funciona no Browser)
	if (typeof window !== "undefined") {
		modal = new tingle.modal({
			footer: false,
			closeMethods: ["button", "overlay"],
			closeLabel: "",
			cssClass: ["cursor"],
		});
	}

	// Retorna a ementa ao clicar na linha da disciplina
	function getEmenta(codCurso, codCurriculo) {
		axios
			.get(
				`https://servico.feevale.br/site/v1/cursos/ementa/${codCurso}/${codCurriculo}`
			)
			.then((res) => {
				const ementa = res.data;

				modal.setContent(`
			<div class="conteudo">
				<h3 class="text-secondary">${ementa.Table[0]?.NomeDisciplina}</h3>
				<p class="text-primary">${ementa.Table[0]?.Ementa}</p>
			</div>
			`);

				modal.open();
			});
	}

	// Lógica relativa aos toggles para expandir as disciplinas
	function ContextAwareToggle({ eventKey, callback }) {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionToggle(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;

		return (
			<>
				<MDBBtn
					className="m-2 float-right"
					color="primary"
					onClick={decoratedOnClick}
				>
					<i
						className={
							isCurrentEventKey
								? "fas fa-times-circle mr-1"
								: "fas fa-chevron-circle-down mr-1"
						}
					></i>
					{isCurrentEventKey ? " FECHAR" : " VEJA A ESTRUTURA CURRICULAR"}
				</MDBBtn>
			</>
		);
	}

	/**
	 * Realiza a filtragem das composições curriculares
	 * @param {*} composicoesCurriculares
	 * @returns
	 */
	function FilterComposicoes(composicoesCurriculares) {
		// Converte um código de currículo (202101, 202002, 201902) para formato de Date do JS
		function ConvertCodCurriculoToDate(CodCurriculo) {
			let codCurriculo = CodCurriculo?.toString() ?? null;

			// caso seja nulo, sai da função
			if (codCurriculo === null) return;

			// Gera a data YYYY-MM-dd (2020-01-01)
			return new Date(
				`${codCurriculo.substr(0, 4)}-${codCurriculo.substr(4, 2)}-01`
			);
		}

		// Retorna se o curso é EAD (contém FEEVALE DIGITAL no nome do currículo)
		function EhEAD(CodTurno) {
			return CodTurno == "E";
		}

		// Retorna se o curso é Híbrido (cursos específicos da instituição, cujos currículos foram criados após 202101)
		function EhHibrido(nomeCurso, codCurriculo) {
			// Lista os cursos que possuem currículo híbrido.
			// Caso seja necessário extender futuramente, adicionar aqui.
			// Identifica esse campo no nome do currículo.
			let cursosHibridos = [
				"administracao",
				"gestao financeira",
				"comercio exterior",
				"processos gerenciais",
				"gestao da producao",
				"sistemas de informacao",
				"ciencia da computacao",
				"ciencias contabeis",
				"engenharia de producao",
				"engenharia eletronica",
				"engenharia quimica",
				"engenharia mecanica",
				"engenharia civil",
				"engenharia eletrica",
				"engenharia de computacao",
			];

			// Verifica se o nome do curso possui algum dos cursos híbridos em seu nome.
			// Verifica se o currículo é 202101
			return cursosHibridos.some(
				(nCurso) => nomeCurso.includes(nCurso) //&& codCurriculo.toString() === "202101"
			);
		}

		// Validação para edge case de Educação Física, mesmo currículo para ambos os cursos (licenciatura e digital)
		// lançado como "Educação Física"
		function EhEDFisica(codCurso) {
			return codCurso !== undefined && codCurso === "3086";
		}

		// Retorna se um curso é do mesmo tipo (tecnólogo, bacharelado ou licenciatura) especificado
		function EhTipo(nomeCurso, tipo) {
			return nomeCurso.indexOf(tipo) > -1;
		}
		
		return (
			composicoesCurriculares
				// Ordena as grades curriculares com base na data de seu currículo (mais antigo para mais novo)
				.sort(function (a, b) {
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return (
						ConvertCodCurriculoToDate(a.CodCurriculo) -
						ConvertCodCurriculoToDate(b.CodCurriculo)
					);
				})
				// Inverte a ordem, deixando os mais novos no topo.
				.reverse()
				.filter((curriculo) => {
					// Converte os cursos do tipo Tecnólogo em "Tecnologia" para validar contra o campo NomeCurso
					if (tipo === "tecnologo") tipo = "tecnologia";

					let CodTurno = curriculo.CodTurno;
					let nomeCurso = NormalizeString(curriculo.NomeCurso);
					let codCurriculo = curriculo.CodCurriculo?.toString();

					// Caso a modalidade selecionada seja:
					switch (modalidade) {
						case "hibrido":
							if (
								!EhEAD(CodTurno) &&
								EhHibrido(nomeCurso, codCurriculo) &&
								EhTipo(nomeCurso, tipo)
							) {
								return true;
							}
							break;
						case "ead":
							if (EhEAD(CodTurno) && ( EhTipo(nomeCurso, tipo) || codCurriculo == 202301 ) ) { // Workaround para educação fisica no RM
								return true;
							}
							break;
						case "presencial":
							if (
								(!EhEAD(CodTurno) &&
									!EhHibrido(nomeCurso, codCurriculo) &&
									EhTipo(nomeCurso, tipo)) ||
								EhEDFisica(curriculo.CodCursoSIAE)
							) {
								return true;
							}
							break;
						default:
							return false;
					}
				})
		);
	}

	return (
		<>
			{FilterComposicoes(composicoesCurriculares).map((curriculo, index) => {
				let estrutura = curriculo.ListaDisciplinas?.Table[0]?.NomeEstrutura;

				// Mostra apenas currículos ativos
				if (curriculo.Linha1.indexOf("Novo") == -1) {
					return null;
				} else {
					return (
						<>
							<Accordion className="pb-4" defaultActiveKey={"0"}>
								<Card
									className={
										GGgetMode() &&
										"border border-secondary text-white bg-primary"
									}
								>
									{/* Cabeçalho das grades curriculares */}
									<Row className="mx-4 pt-4">
										<Col>
											<h5 className="font-weight-bold">
												{curriculo.NomeCurso}
												<br />
												<MDBBadge
													color={
														curriculo.Linha1.indexOf("Novo") > -1
															? GGgetMode()
																? "secondary"
																: "primary"
															: "grey"
													}
													size="2x"
													className="mt-2"
												>
													{curriculo.Linha1}
												</MDBBadge>
											</h5>
											<p className="small py-2">
												{/* {!GGgetMode() && (
													<>
														{curriculo.AtosLegais.map((atosLegais) => (
															<>
																<span>{atosLegais}</span>
															</>
														))}
														<br />
													</>
												)} */}
												{curriculo.Linha2}
												<br />
												{curriculo.TempoConclusao}
											</p>
										</Col>
									</Row>

									<Card.Header
										className={GGgetMode() ? "p-0 m-0 bg-dark" : "p-0 m-0"}
									>
										<ContextAwareToggle
											eventKey={`'${index}'`}
										></ContextAwareToggle>
									</Card.Header>

									<Accordion.Collapse eventKey={`'${index}'`}>
										<Card.Body className="col-12 p-0 m-0">
											<Row className="col-12 p-0 m-0 table-responsive">
												<MDBTable
													striped
													className={
														GGgetMode()
															? "col-12 table w-100 table-sm mb-0 text-center table-dark"
															: "col-12 table w-100 table-sm mb-0 text-center "
													}
												>
													<MDBTableHead>
														<tr>
															{/* <th className=
																{GGgetMode() ?
																	"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																:
																	"font-weight-bold text-nowrap border-top"
																}>
																SEM.
															</th> */}
															{/* {!GGgetMode() && (
																<>
																	<th className="font-weight-bold text-nowrap border-top">
																		CÓDIGO
																	</th>
																</>
															)} */}
															{/* <th className=
																{GGgetMode() ?
																	"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																:
																	"font-weight-bold text-nowrap border-top"
																}>
																COMPONENTES CURRICULARES
															</th> */}
															{/* <th className=
																{GGgetMode() ?
																	"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																:
																	"font-weight-bold text-nowrap border-top"
																}>
																CARGA HORÁRIA (H)
															</th> */}
															{/* {!GGgetMode() && (
																<>
																	<th className="font-weight-bold text-nowrap border-top">
																		REQUISITOS
																	</th>
																</>
															)} */}
														</tr>
													</MDBTableHead>
													<MDBTableBody>
														{curriculo.ListaDisciplinas?.Table.map(
															(disciplina, index) => {
																let header = null;

																// Verifica se a estrutura atual é a primeira ou é diferente da estrutura atual da disciplina
																// Usado para plotagem das linhas de módulos
																if (
																	index === 0 ||
																	estrutura !== disciplina.NomeEstrutura
																) {
																	estrutura = disciplina.NomeEstrutura;

																	// Define o retorno de header
																	header = (
																		<tr color="primary">
																			<th
																				colSpan="5"
																				className="font-weight-bold text-center"
																			>
																				{disciplina.NomeEstrutura}
																			</th>
																		</tr>
																	);
																}

																// Retorna a grade de um módulo
																return (
																	<>
																		{header}

																		<tr
																			style={
																				disciplina.Disciplina.indexOf(
																					"OPTATIVA"
																				) === -1
																					? { cursor: "pointer" }
																					: null
																			}
																			onClick={() => {
																				if (
																					disciplina.Disciplina.indexOf(
																						"OPTATIVA"
																					) === -1
																				) {
																					getEmenta(
																						curriculo.CodCursoSIAE,
																						disciplina.Codigo
																					);
																				}
																			}}
																		>
																			<td colSpan="2">{disciplina.Semestre}</td>
																			{/* {!GGgetMode() && (
																				<>
																					<td>{disciplina.Codigo}</td>
																				</>
																			)} */}
																			<td
																				colSpan="3"
																				className="text-nowrap text-left"
																			>
																				{disciplina.Disciplina}
																			</td>
																			{/* <td>{disciplina.CH}</td> */}
																			{/* {!GGgetMode() && (
																				<>
																					<td className="small">
																						{disciplina.PreRequisito}
																					</td>
																				</>
																			)} */}
																		</tr>
																	</>
																);
															}
														)}
													</MDBTableBody>
												</MDBTable>
												{/* {curriculo.Observacoes?.Table?.HasValue && (
													<>
														<MDBTable
															striped
															className=
															{GGgetMode() ?
																"col-12 table w-100 table-sm mb-0 text-center table-dark"
															:
																"col-12 table w-100 table-sm mb-0 text-center "
															}
														>
															<MDBTableHead>
																<tr>
																	<th className=
																		{GGgetMode() ?
																			"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																		:
																			"font-weight-bold text-nowrap border-top"
																		}>
																		Observações
																	</th>
																</tr>
															</MDBTableHead>
															<MDBTableBody>
																{curriculo.Observacoes?.Table.map(
																	(observacao, index) => {
																		// Insere as observações
																		if (index > 0) {
																			return (
																				<tr>
																					<td className="text-left pl-5">
																						{`${observacao.Ordem - 1} ${
																							observacao.Observacao
																						}`}
																					</td>
																				</tr>
																			);
																		}
																	}
																)}
															</MDBTableBody>
														</MDBTable>
													</>
												)} */}
												{/* {curriculo.ComponentePadrao && (
													<>
														<MDBTable
															striped
															className=
															{GGgetMode() ?
																"col-12 table w-100 table-sm mb-0 text-center table-dark"
															:
																"col-12 table w-100 table-sm mb-0 text-center"
															}
														>
															<MDBTableHead>
																<tr>
																	{!GGgetMode() && (
																		<>
																			<th className="font-weight-bold text-nowrap border-top">
																				CÓDIGO
																			</th>
																		</>
																	)}
																	<th className=
																		{GGgetMode() ?
																			"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																		:
																			"font-weight-bold text-nowrap border-top"
																		}>
																		COMPONENTES CURRICULARES
																	</th>
																	<th className=
																		{GGgetMode() ?
																			"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																		:
																			"font-weight-bold text-nowrap border-top"
																		}>
																		CARGA HORÁRIA (H)
																	</th>
																	{/* {!GGgetMode() && (
																		<>
																			<th className="font-weight-bold text-nowrap border-top">
																				REQUISITOS
																			</th>
																		</>
																	)}
																</tr>
															</MDBTableHead>
															<MDBTableBody>
																{curriculo.ComponentePadrao?.Table.map(
																	(componente, index) => {
																		let header = null;

																		if (
																			index === 0 ||
																			estrutura !== componente?.NomeComponente
																		) {
																			header = (
																				<tr>
																					<th
																						colSpan="4"
																						className="font-weight-bold"
																					>
																						{
																							(estrutura =
																								componente?.NomeComponente)
																						}
																					</th>
																				</tr>
																			);
																		}

																		return (
																			<>
																				{header}
																				<tr>
																					{!GGgetMode() && (
																						<>
																							<td className="text-left pl-5">
																								{componente.Codigo}
																							</td>
																						</>
																					)}
																					<td>{componente.Disciplina}</td>
																					<td>{componente.CH}</td>
																					{!GGgetMode() && (
																						<>
																							<td>{componente.PreRequisito}</td>
																						</>
																					)}
																				</tr>
																			</>
																		);
																	}
																)}
															</MDBTableBody>
														</MDBTable>
													</> 
												)}*/}
												{/* {curriculo.AtividadeComplementar && (
													<>
														<MDBTable
															striped
															className=
															{GGgetMode() ?
																"col-12 table w-100 table-sm mb-0 text-center table-dark"
															:
																"col-12 table w-100 table-sm mb-0 text-center"
															}
														>
															<MDBTableHead>
																<tr>
																	<th className=
																		{GGgetMode() ?
																			"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																		:
																			"font-weight-bold text-nowrap border-top"
																		}>
																		{
																			curriculo.AtividadeComplementar?.Table[0]
																				?.ChAtividadeCurso
																		}
																		H DE ATIVIDADES COMPLEMENTARES
																	</th>
																	<th className=
																		{GGgetMode() ?
																			"font-weight-bold text-nowrap border border-dark border-left-0 border-bottom-0 border-right-0"
																		:
																			"font-weight-bold text-nowrap border-top"
																		}>
																		COMPONENTES CURRICULARES
																	</th>
																</tr>
															</MDBTableHead>
															<MDBTableBody>
																{curriculo.AtividadeComplementar?.Table.map(
																	(complementar) => (
																		<tr key={complementar.Tipo}>
																			<td className="text-left pl-5">
																				{complementar.Tipo}
																			</td>
																			<td>
																				Aproveitamento de até{" "}
																				{complementar.CHMaxima}H{" "}
																				{complementar.Observacao}
																			</td>
																		</tr>
																	)
																)}
															</MDBTableBody>
														</MDBTable>
														{/* {curriculo.ObservacaoAtividadeComplementar.Table
															.HasValue && (
															<MDBTable
																striped
																className=
																{GGgetMode() ?
																	"col-12 table w-100 table-sm mb-0 text-center table-dark"
																:
																	"col-12 table w-100 table-sm mb-0 text-center "
																}
															>
																<MDBTableHead>
																	<tr>
																		<th
																			className="font-weight-bold text-uppercase border-top"
																			style={{
																				borderWidth: "6px !important",
																			}}
																		>
																			OBSERVAÇÕES (ATIVIDADES COMPLEMENTARES)
																		</th>
																	</tr>
																</MDBTableHead>
																<MDBTableBody>
																	{curriculo.ObservacaoAtividadeComplementar?.Table.map(
																		(observacoesComplementar) => (
																			<tr
																				key={observacoesComplementar.Observacao}
																			>
																				<td className="text-left pl-5">
																					{observacoesComplementar.Observacao}
																				</td>
																			</tr>
																		)
																	)}
																</MDBTableBody>
															</MDBTable>
														)} 
													</>
												)} */}
											</Row>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</>
					);
				}
			})}
		</>
	);
}
