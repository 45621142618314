import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import BadgeSelected from "../../components/BadgeSelected/BadgeSelected";
import PropTypes from "prop-types";
import InnerLayout from "../../Layouts/InnerLayout/InnerLayout";

import CardDisciplina from "../../components/CardDisciplina/CardDisciplina";
import CardDisciplinaPaliativo from "../../components/CardDisciplinaPaliativo/CardDisciplinaPaliativo"

import { MDBCol } from "mdbreact";
import { GGgetMode } from "../../services/gglocalstorage";
import { ValidateHashPath } from "../../services/hashpathservice";

export default function CursoDisciplinasPage({
	pageContext: { curso, composicoesCurriculares, listaGrupoCursos },
}) {
	CursoDisciplinasPage.propTypes = {
		pageContext: PropTypes.object.isRequired,
		curso: PropTypes.object.isRequired, // array de cursos
		cursosDaArea: PropTypes.object.isRequired, // array de cursos da area
		composicoesCurriculares: PropTypes.object.isRequired, // array de composicoes curriculares.
	};

	function isNumeric(char) {
		return !isNaN(char);
	  }

	  let identifier = "";
	  
	  function replaceIdentifierIfNotNumeric(text) {
		if (text && text.length > 0) {
		  if (!isNumeric(text.charAt(0))) {
			return identifier;
		  }
		}
		return text;
	  }

	let selectedIndex =
		typeof window !== "undefined"
			? ValidateHashPath(curso.Items.length, location.hash)
			: 0;
	let cursoSelecionado = curso.Items[selectedIndex];

	return (
		<InnerLayout curso={curso} listaGrupoCursos={listaGrupoCursos}>
			<Container>
				<Row className="my-5">
					<Col className="text-center">
						<BadgeSelected
							tipo={cursoSelecionado.Informacoes.Tipo.Value}
							modalidade={cursoSelecionado.Informacoes.Modalidade.Value}
						/>
					</Col>
				</Row>

				{/* PALIATIVO */}
				{[identifier].includes(
					replaceIdentifierIfNotNumeric(cursoSelecionado.Integracao.CodComposicao.Value, identifier)
				) ? (
					<CardDisciplinaPaliativo
						CodComposicaoCurricular={
							cursoSelecionado.Integracao.CodComposicao.Value
						}
					/>
				) : (
					<CardDisciplina
						composicoesCurriculares={composicoesCurriculares}
						tipo={curso.Opcoes[selectedIndex]
							.substring(0, curso.Opcoes[selectedIndex].indexOf("-") - 1)
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.toLowerCase()}
						modalidade={curso.Opcoes[selectedIndex]
							.substring(curso.Opcoes[selectedIndex].indexOf("-") + 2)
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.toLowerCase()}
					></CardDisciplina>
				)}

				{/* {!GGgetMode() && (
				<MDBCol sm={12} className="text-justify my-2">
					As atividades complementares serão avaliadas pela coordenação e
					deverão seguir o seguinte critério: a atividade deve ser realizada
					durante o período em que o aluno se encontra matriculado no curso; o
					documento deve conter carga horária, conteúdo da atividade e período
					de realização da mesma.
					<br />
					As atividades devem ser comprovadas mediante a apresentação de
					certificados, expedidos em meio digital ou físico pela instituição ou
					órgão emitente e deverão atender no mínimo os requisitos de
					autenticidade, para garantir total segurança das informações
					prestadas, conforme abaixo:
					<ul>
						<li>
							Certificados digitalizados ou cópias simples: deverá conter
							assinatura física, carimbo, nome, cargo e assinatura. Poderá ser
							solicitado apresentação do original, para averiguação da
							autenticidade.
						</li>
						<li>
							Certificados Digital: emitido com assinatura eletrônica, desde que
							o número identificador da autenticação esteja válido para consulta
							e verificação da autenticidade no endereço eletrônico do emitente.
						</li>
						<li>
							Certificados Nato Digital: assinatura digital com ICP-Brasil,
							aceita de acordo com MP n.º 2200-2/2001, que visa garantir a
							autenticidade, a integridade e a validade do documento nato
							digital emitido.
						</li>
					</ul>
					<br />
					Caso o documento entregue não contenha algum dos elementos citados, o
					pedido do aluno automaticamente estará indeferido.
				</MDBCol>
			)} */}
			</Container>
		</InnerLayout>
	);
}
